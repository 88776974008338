#pageWrap {
	overflow: hidden;

	body:not(.index) & {
		margin-top: rem(50px);

		@include breakpoint(large) {
			margin-top: rem(91px +18px);
		}

		@include breakpoint(giant) {
			margin-top: rem(91px*2);
		}

		@include breakpoint(huge) {
			margin-top: rem(190px);
		}
	}
}

.padding-outter {
	padding: 3rem 0 rem(54px - 26px);

	@include breakpoint(medium) {
		padding: 5rem 0 4rem;
	}

	@include breakpoint(large) {
		padding: 7rem 0 6rem;
	}


	@include breakpoint(huge) {
		padding: rem(180px) 0 rem(150px);
	}

	@include breakpoint(cut-2000) {
		padding: rem(200px) 0 rem(170px);
	}

	.alebrijes-img {
		width: auto;
		height: auto;
	}

	body:not(.index) & {

		@include breakpoint (large) {
			padding: rem(126px) 0 rem(126px - 30px);
		}
		@include breakpoint(huge) {
			padding: rem(180px) 0 rem(150px);
		}
	
		@include breakpoint(cut-2000) {
			padding: rem(200px) 0 rem(170px);
		}

	}
	body.contact  &{
		padding-bottom: 0;
	}

	body.alebrijesPhp & {
		@include breakpoint(large,max){
			padding-bottom: rem(54px);
		}
	}

}

// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;

	@include responsiveImage('layout/bgHeader.jpg', (tiny, small), false);
	background-size: cover;
	background-position: center;
	// &.lazyLoaded {
	// }

	>.row {
		padding: 7rem 0 5rem;

		@include breakpoint(small) {
			padding: 9rem 0 7rem;
		}

		@include breakpoint(medium) {
			padding: 12rem 0 9rem;
		}

		@include breakpoint(large) {
			padding: 10rem 0 14rem;
		}

		@include breakpoint(giant) {
			padding: 13rem 0 16rem;
		}

		@include breakpoint(huge) {
			padding: 15rem 0 16rem;
		}

		@include breakpoint(cut) {
			padding-top: rem(330px);
			padding-bottom: rem(330px)
		}
	}

	body.about & {
		margin-bottom: 0;

		@include breakpoint(large) {
			margin-bottom: rem(126px);
		}
		@include breakpoint(huge) {
			margin-bottom: rem(180px);
		}
	
		@include breakpoint(cut-2000) {
			margin-bottom: rem(200px);
		}
	}


	&:after {
		@include breakpoint(large) {
			content: '';
			background: $green;
			height: 100%;
			width: 7rem;
			display: block;
			position: absolute;
			right: 0;
			top: 0;
		}

		@include breakpoint(giant) {
			width: 12rem;
		}

		@include breakpoint(cut) {
			width: calc(((100vw - #{$rowMaxWidth}) / 2) + 17rem);
		}



		@include breakpoint(cut-2000) {
			width: calc(((100vw - #{$rowMaxWidth}) / 2) + 17rem);
		}
	}



	blockquote {
		font-weight: 800;

		span,
		strong {
			line-height: 1.1em;
			display: block;
			color: $light;

			@include breakpoint(giant) {
				line-height: 1.05;
			}

			@include breakpoint(cut) {
				line-height: 1;
			}

		}

		span {
			font-size: rem(30px);

			@include breakpoint(tiny) {
				font-size: rem(35px);
			}

			@include breakpoint(small) {
				font-size: rem(45px);
			}

			@include breakpoint(medium) {
				font-size: rem(55px);
			}

			@include breakpoint(large) {
				font-size: rem(60px);
			}

			@include breakpoint(giant) {
				font-size: rem(70px);
			}

			@include breakpoint(cut) {
				font-size: rem(80px);
			}
		}

		strong {
			font-size: rem(68px);

			@include breakpoint(tiny) {
				font-size: rem(75px);
			}

			@include breakpoint(small) {
				font-size: rem(90px);
			}

			@include breakpoint(medium) {
				font-size: rem(110px);
			}

			@include breakpoint(large) {
				font-size: rem(125px);
			}

			@include breakpoint(giant) {
				font-size: rem(140px);
			}

			@include breakpoint(huge) {
				font-size: rem(150px);
			}

			@include breakpoint(full) {
				font-size: rem(160px);
			}

			@include breakpoint(cut) {
				font-size: rem(170px);
			}
		}

		.bottom {
			margin-top: 1.5rem;

			@include breakpoint(tiny) {
				padding-right: 9rem;
			}

			@include breakpoint(giant) {
				display: flex;
				align-items: center;
			}

			.btn {
				@include breakpoint(giant) {
					margin-left: 1.5rem;
				}
			}

			p {
				line-height: 1.3em;
				margin-bottom: 1.6rem;
				color: rgba($light, .3);
				font-weight: 500;
				max-width: rem(280px);

				@include breakpoint(tiny) {
					font-size: rem(18px);
					max-width: rem(300px);
				}


				@include breakpoint(small) {
					max-width: rem(350px);
					font-size: rem(20px);
				}

				@include breakpoint(giant) {
					margin-bottom: 0;
				}


				@include breakpoint(medium) {
					max-width: rem(430px);

				}
			}
		}
	}

	.image-cont {
		position: relative;
		z-index: 9;

		@include breakpoint(cut) {
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%);
			width: 100vw;
			max-width: 100vw;
		}


		img {
			position: absolute;
			right: -67%;
			bottom: -12rem;
			max-width: 730px;

			@include breakpoint(small) {
				bottom: -15rem;
			}

			@include breakpoint(medium) {
				bottom: -17rem;
				right: -500px;
			}

			@include breakpoint(large) {
				right: -40%;
				bottom: -23rem;

			}

			@include breakpoint(giant) {
				right: -20%;
				bottom: -32rem;
			}

			@include breakpoint(huge) {
				right: -15%;
			}

			@include breakpoint(full) {
				right: -14%;
				bottom: -32rem;
			}

			@include breakpoint(cut) {
				right: -6rem;
				bottom: -13rem;
			}

			@include breakpoint(cut-2000) {
				max-width: calc(((100vw - #{$rowMaxWidth}) / 2) + 40rem);
				bottom: -14rem;
			}

			@include breakpoint(cut-2200) {
				max-width: 1000px;
			}

		}
	}

	.header-bottom {
		background: $dark-green;
		padding: 2rem 4rem 2rem 0;
		position: relative;
		z-index: 1;




		@include breakpoint(huge) {
			padding: rem(35px) 4rem rem(35px) 0;
		}


		.col {
			@include breakpoint(medium) {
				display: flex;
				align-items: center;
			}

			p {
				color: $light;
				line-height: 1.4em;
				font-size: 16px;
				margin-bottom: 1.1rem;

				@include breakpoint(small) {
					font-size: 17px;
				}

				@include breakpoint(medium) {
					margin-bottom: 0;
					margin-right: 2rem;
				}


				@include breakpoint(large) {
					font-size: 19px;
				}


				@include breakpoint(huge) {
					font-size: 22px;
				}


				strong {
					font-size: 1.2em;
					margin-bottom: .4rem;
					display: block;

					body:not(.index) & {
						@include breakpoint(large) {
							display: inline;
						}

					}

					@include breakpoint(full) {
						font-size: 1.3em;
						display: inline;
					}

					@include breakpoint(cut) {
						margin-right: .8rem;
					}
				}
			}
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	.darker-outter {
		background: $darker;

		.padding-outter {
			@include breakpoint(medium) {
				padding: rem(90px) 0;
			}

			@include breakpoint(large) {
				padding: rem(126px) 0;
			}

			@include breakpoint(huge) {
				padding: rem(180px) 0;
			}

			@include breakpoint(cut-2000) {
				padding: rem(200px);
			}
		}

		.centered-img {
			display: flex;
			flex-flow: column;
			align-items: center;

			@include breakpoint(medium) {
				flex-flow: row;
			}


			img {
				background: none;
				max-width: 70%;
				transform: rotate(-9deg);
				transform-origin: center;
				margin-bottom: 0;
				transition: all 300ms ease-in-out;

				@include breakpoint(tiny) {
					max-width: 50%;
				}

				@include breakpoint(small) {
					max-width: 40%;
				}

				@include breakpoint(medium) {
					max-width: 30%;
				}

				@include breakpoint(large) {
					max-width: rem(230px)
				}

				&.lazyLoaded {
					transform: rotate(0)
				}
			}
		}

		p {
			color: rgba($light, .3);
			text-align: center;
			margin-top: 2.6rem;
			padding-top: 2.6rem;
			border-top: 1px solid rgba($light, .2);

			@include breakpoint(tiny) {
				padding: 2.6rem 2rem 0;
			}

			@include breakpoint(small) {
				padding: 2.6rem 0 0;
			}

			@include breakpoint(medium) {
				padding: 1rem 0 1rem 2.6rem;
				margin: 0 0 0 2.6rem;
				border-top: 0;
				border-left: 1px solid rgba($light, .2);
				text-align: left;
			}

			@include breakpoint(huge) {
				padding: rem(50px) 0 rem(50px) rem(70px);
				margin: 0 0 0 rem(70px);
			}


			@include breakpoint(cut) {
				padding: rem(70px) 0 rem(70px) rem(100px);
				margin: 0 0 0 rem(100px);
			}

		}
	}

	.spaced-images {}

	.green-outter {
		background: $dark-green;
		padding: 3rem 0;

		@include breakpoint(large) {
			padding: rem(53px) 0;
		}

		.col {
			@include breakpoint(huge) {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}

		p,
		ul,
		li {
			color: $light;

			&.h2 {
				@include breakpoint(tiny) {
					text-align: center;
				}

				@include breakpoint(huge) {
					text-align: left;
					margin-bottom: 0;
				}

				@include breakpoint(cut) {
					font-size: rem(35px);
				}

			}
		}

		ul {
			margin-bottom: 0;

			@include breakpoint(tiny) {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-around;
			}

			@include breakpoint(small) {
				justify-content: center;
			}



			li {
				padding-left: 0;
				display: flex;
				align-items: center;
				position: relative;

				&:before {
					color: rgba($light, .4);
					text-indent: 0;
					margin-right: .6rem;
					font-size: .96em;
				}

				@include breakpoint(tiny) {
					width: 50%;
					font-size: rem(15px);
				}

				@include breakpoint(small) {
					font-size: rem(16px);
					width: auto;
				}

				@include breakpoint(medium) {
					font-size: rem(18px);
				}

				@include breakpoint(giant) {
					font-size: rem(19px);
				}

				@include breakpoint(huge) {
					margin-bottom: 0 !important;
				}


				@include breakpoint(full) {
					font-size: rem(20px);
				}

				&:not(:last-child) {
					margin-bottom: .7rem;
					padding-bottom: .7rem;

					@include breakpoint(tiny) {
						padding-bottom: 0;
					}

					&:after {
						content: '';
						height: 1px;
						width: rem(59px);
						position: absolute;
						left: 0;
						bottom: 0;
						display: block;
						background: rgba($light, .3);
					}
				}

				&:first-child {
					@include breakpoint(tiny) {
						&:after {
							display: none;
						}
					}

					@include breakpoint(small) {
						width: 100%;
						justify-content: center;
					}

					@include breakpoint(large) {
						width: auto;
						justify-content: flex-start;
						padding-right: .8rem;
						margin-right: .8rem;

						&:after {
							display: block;
							left: auto;
							right: 0;
							content: '';
							height: rem(30px);
							width: 1px;
							position: absolute;
							top: 50%;
							bottom: auto;
							transform: translateY(-50%);
							display: block;
							background: rgba($light, .3);
						}
					}

					@include breakpoint(giant) {
						padding-right: rem(25px);
						margin-right: rem(25px);
					}

				}

				&:not(:first-child) {
					@include breakpoint(tiny) {
						padding-left: .6rem;


						&:after {
							content: '';
							height: rem(30px);
							width: 1px;
							position: absolute;
							left: 0;
							top: 50%;
							bottom: auto;
							transform: translateY(-50%);
							display: block;
							background: rgba($light, .3);
						}
					}

					@include breakpoint(small) {
						padding-left: .8rem;
					}

					@include breakpoint(medium) {
						padding-left: 1rem;
					}

					@include breakpoint(large) {
						padding-left: .8rem;
					}

					@include breakpoint(giant) {
						padding-left: rem(25px);
					}
				}


				&:nth-of-type(1) {
					&:before {
						@extend .icon-seed;
					}
				}

				&:nth-of-type(2) {

					&:before {
						@extend .icon-car;
					}

					@include breakpoint(small) {
						padding-left: 0;
						padding-right: .8rem;
						margin-right: .8rem;

						&:after {
							left: auto;
							right: 0;
						}
					}

					@include breakpoint(medium) {
						padding-right: 1rem;
						margin-right: 1rem;
					}

					@include breakpoint(large) {
						padding-right: .8rem;
						margin-right: .8rem;
					}

					@include breakpoint(giant) {
						margin-right: rem(25px);
						padding-right: rem(25px);
					}
				}

				&:nth-of-type(3) {
					&:before {
						@extend .icon-clock;
					}

					@include breakpoint(tiny) {
						padding-left: 0;

						&:after {
							display: none;
						}
					}
				}

				&:nth-of-type(4) {
					&:before {
						@extend .icon-parking;
					}

					@include breakpoint(small) {
						margin-left: .8rem;
					}

					@include breakpoint(medium) {
						margin-left: 1rem;
					}

					@include breakpoint(large) {
						margin-left: .8rem;
					}

					@include breakpoint(giant) {
						margin-left: rem(25px);
					}
				}
			}
		}
	}

	.teaser {
		overflow: hidden;
		padding: rem(54px) 0 rem(54px - 30px);

		@include breakpoint(medium) {
			padding: 12rem 0 4rem;
		}

		@include breakpoint(large) {
			padding: calc(7rem + 170px) 0 7rem;
		}

		@include breakpoint(giant) {
			padding: calc(7rem + 170px) 0 rem(126px + 30px);
		}

		@include breakpoint(huge) {
			padding: 360px 0 229px;
		}

		.teaser-outter {
			margin-bottom: 3rem;
			opacity: 0;
			transform: translateY(30px);
			transition: all 500ms ease-in-out;

			&.isOnScreen {
				opacity: 1;
				transform: translateY(0);

				&:not(:last-child),
				&:last-child {
					.inner {
						img {
							@include breakpoint(giant) {
								transform: translate(-50%, -68%);
							}
						}
					}
				}
			}

			&.second {
				transition-delay: 300ms;
			}

			&.third {
				transition-delay: 600ms;
			}

			@include breakpoint(giant) {
				margin-bottom: 0;
			}

			&:not(:last-child) {
				@include breakpoint(medium) {
					margin-bottom: 5rem;
				}

				@include breakpoint(giant) {
					margin-bottom: 0;
				}

				.inner {
					@include breakpoint(medium) {
						height: 100%;
					}

					.inner-cont {
						@include breakpoint(medium) {
							padding: 3rem 3rem 4rem 3rem;
							padding-top: 10rem;
							height: 100%;
						}

						@include breakpoint(large) {
							padding-top: 8.5rem;
						}

						@include breakpoint(giant) {
							padding: 3rem 2rem 4rem 2rem;
							padding-top: 7.5rem;
						}


						p {
							@include breakpoint(medium) {
								text-align: center;
							}
						}

						.btn {
							@include breakpoint(medium) {
								left: 50%;
								transform: translate(-50%, 50%);
							}
						}
					}

					img[data-src] {
						background: transparent;
					}

					img {
						@include breakpoint(medium) {
							top: 0;
							left: 50%;
							transform: translate(-50%, -50%);
						}

						@include breakpoint(large) {
							transform: translate(-50%, -60%);
						}

						@include breakpoint(giant) {
							transform: translate(-50%, -78%);
							transition: all 300ms ease-in-out;
							transition-delay: 500ms;
						}
					}
				}
			}

			.inner {
				position: relative;
				background: $darker;


				@include breakpoint(giant) {
					height: 100%;
				}

				p {
					color: $light;
					margin-bottom: .8rem;

					@include breakpoint(giant) {
						text-align: center;
					}

					&:not(.h5) {
						color: rgba($light, .3);
						margin-bottom: 0;
					}
				}

				img {
					position: absolute;
					top: 50%;
					left: -208px;
					transform: translateY(-50%);
					margin-bottom: 0;
					max-width: 290px;
					transition: all 300ms ease-in-out;
					transition-delay: 100ms;

					@include breakpoint(tiny) {
						left: -158px;
					}

					@include breakpoint(small) {
						left: -128px;
					}

					@include breakpoint(medium) {
						left: -38px;
					}

					@include breakpoint(giant) {
						top: 0;
						left: 50%;
						transform: translate(-50%, -78%);
						transition: all 300ms ease-in-out;
						transition-delay: 500ms;
						max-width: 320px;
					}
				}

				.inner-cont {
					padding: 1.8rem 1.8rem 3.3rem;
					padding-left: 4.8rem;
					position: relative;


					@include breakpoint(tiny) {
						padding-left: 8.5rem;
					}


					@include breakpoint(small) {
						padding: 2.8rem 2.8rem 4.3rem;
						padding-left: 10.5rem;
					}

					@include breakpoint(medium) {
						padding: 3rem 3rem 4.3rem;
						padding-left: 16rem;
					}

					@include breakpoint(large) {
						padding: 3rem 5rem 4.3rem;
						padding-left: 17rem;
					}

					@include breakpoint(giant) {
						padding: 3rem 2rem 4rem 2rem;
						padding-top: 7.5rem;
						height: 100%;
					}

					@include breakpoint(cut) {
						padding-top: 140px;
						padding-left: 55px;
						padding-right: 55px;
						padding-bottom: 110px;
					}

					.btn {
						position: absolute;
						bottom: 0;
						transform: translateY(50%);

						@include breakpoint(giant) {
							left: 50%;
							transform: translate(-50%, 50%);
						}
					}
				}
			}
		}
	}

	.slider-cont {
		position: relative;

		body.about:not(.cmsBackend) & {

			.images-slider {
				padding-top: 0;

				@include breakpoint(large) {
					transform: translateY(-50%);
				}


				@include breakpoint(huge) {
					height: calc(100% - 9rem);
				}

				@include breakpoint(cut) {
					height: calc(100% - 12rem);
				}

				>div {
					height: 100%;
					width: 100%;
				}
			}
		}

		body:not(.cmsBackend) & {

			.swiper-container,
			.images-slider {
				width: 100%;
				height: 14rem;
				display: flex;
				flex-flow: column-reverse;
				padding-top: 3rem;

				@include breakpoint(tiny) {
					height: 17rem;
				}

				@include breakpoint(small) {
					height: 22rem;
				}

				@include breakpoint(medium) {
					height: 26rem;
					padding-top: 5rem;
				}

				@include breakpoint(large) {
					left: 0;
					position: absolute !important;
					width: 50vw;
					height: calc(100% - 8rem);
					flex-flow: column;
					justify-content: flex-end;
					align-items: flex-end;
					top: 50%;
					transform: translateY(-50%);
				}

				@include breakpoint(huge) {
					height: calc(100% - 5rem);
				}

				@include breakpoint(cut) {
					height: calc(100% - 7rem);
					width: calc(100vw - ((100vw - #{$rowMaxWidth}) / 2) - (#{$rowMaxWidth} / 2));
					transform: translateY(-48%);
				}



				.swiper-slide {
					background-position: center;
					background-size: cover;
				}

				.swiper-nav {
					display: flex;
					padding: 0 $baseGap $baseGap*2;

					@include breakpoint(large) {
						padding: 20px $baseGap 0;
					}

					@include breakpoint(huge) {
						padding: 45px $baseGap*2 0;
					}



					.swiper-button-prev,
					.swiper-button-next {
						position: relative;
						top: auto;
						bottom: auto;
						left: auto;
						right: auto;
						font-size: 0;

						&:before {
							@extend .icon;
							@extend .icon-circle-right;
							font-size: 24px;
							color: rgba($green, .5);
						}

						&:hover {
							&:before {
								color: rgba($green, .8);
								transition: all 300ms ease-in-out;
							}
						}
					}

					.swiper-button-prev {
						padding-right: 1.2rem;
						margin-right: 1.2rem;
						border-right: 1px solid lighten($text, 20%);

						&:before {
							@extend .icon-circle-left;
						}
					}
				}
			}

		}


		.no-padding {
			padding-left: 0;
			padding-right: 0;
		}

		.padding-right {
			@include breakpoint(large) {
				padding-right: 5rem;
			}
		}

		.inner-green {
			background: $green;
			color: $light;
			position: relative;
			z-index: 9;
			padding: 1.8rem 1.8rem rem(10px);

			a {
				&:hover {
					color: $darker;
				}
			}

			@include breakpoint(tiny) {
				padding: 2.8rem 2.8rem 2rem;
			}

			@include breakpoint(medium) {
				padding: 3.8rem 2.8rem 2.8rem;
			}

			@include breakpoint(giant) {
				padding: 4.8rem 3.8rem 4rem;
			}

			@include breakpoint(huge) {
				padding: 5.8rem 4.8rem 5rem;
			}

			@include breakpoint(cut) {
				padding: 180px 140px 160px;

				body.contact & {
					padding: 100px 100px 120px;
				}
			}

			&:after {
				@include breakpoint(cut) {
					content: '';
					height: 100%;
					width: calc((110vw - 1620px) / 2);
					left: 100%;
					right: 5px;
					top: 0;
					display: block;
					background: $green;
					position: absolute;
				}
			}



			&:before {
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 25px 22px 25px;
				border-color: transparent transparent $green transparent;
				position: absolute;
				bottom: 100%;
				left: 1.8rem;

				@include breakpoint(tiny) {
					left: 2.8rem;
				}

				@include breakpoint(large) {
					top: 50%;
					bottom: auto;
					transform: rotate(-90deg) translateY(-52%);
					right: calc(100% - 16px);
					left: auto;
					transform-origin: center top;
				}


			}
		}
	}

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom: rem($baseGap*2);
	}
}

#privacyPolicy {
	a {
		display: inline-block;
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	background: $green;

	body.about &,body.contact & {
		margin-top: 0;

		@include breakpoint(large) {
			margin-top: rem(126px);
		}
		@include breakpoint(huge) {
			margin-top: rem(180px);
		}
	
		@include breakpoint(cut-2000) {
			margin-top: rem(200px);
		}
	}

	body.index & {
		margin-top: 3rem;

		@include breakpoint(medium) {
			margin-top: 5rem;
		}

		@include breakpoint(large) {
			margin-top: rem(126px);
		}

		@include breakpoint(huge) {
			margin-top: rem(180px);
		}

		@include breakpoint(cut) {
			margin-top: rem(200px);
		}
	}
	// body.contact &{
	// 	@include breakpoint(large){
	// 		margin-top: rem(126px);
	// 	}
	// }


	.googleMaps {

		body.cmsBackend & {
			display: none;
		}

		padding-bottom: 0;
		height: rem(300px);

		@include breakpoint(large) {
			height: rem(550px);
		}

		iframe {
			height: 100%
		}
	}

	.top-footer {
		background: $light;
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;

		@include breakpoint(full,max){
			padding-left: rem($baseGap);
			padding-right: rem($baseGap);
		}

		@include breakpoint(huge) {
			padding-top: rem(85px);
			padding-bottom: rem(85px);
		}


		.img-after {
			position: relative;
			display: flex;
			align-items: center;

			img {
				position: absolute;
				right: -240px;
				margin-bottom: 0;
				top: 50%;
				transform: translateY(-40%);
				max-width: rem(285px);

				@include breakpoint(tiny) {
					top: 2.5rem;
					transform: none;
					right: -120px;
				}

				@include breakpoint(small) {
					right: -80px;
					max-width: rem(350px);
				}

				@include breakpoint(medium) {
					right: auto;
					left: 100%;
					top: 0;
					transform: translate(-50%, calc(-50% - 2.5rem));
					max-width: rem(210px);
				}

				@include breakpoint(huge) {
					max-width: rem(290px);
					transform: translate(-50%, calc(-50% - 85px));
				}
			}

			ul {
				margin-bottom: 1.5rem;
				padding-bottom: 1.5rem;
				position: relative;

				@include breakpoint(medium) {
					margin-bottom: 0;
					padding-bottom: 0;
				}

				@include breakpoint(giant) {
					display: flex;
				}

				/* 				&:after {
	content:'';
	height: 1px;
	background: $grey;
	display: block;
	width: rem(90px);
	position: absolute;
	left: 0;
	bottom: 0;

	@include breakpoint(medium) {
		display: none;
	}
} */

				li {

					.phone,
					.email {
						&:before {
							@extend .icon;
							margin-right: .5rem;
							color: $grey;
						}
					}

					.phone {
						font-weight: 300;

						@include breakpoint(giant) {
							position: relative;
							padding-right: rem(15px);
							margin-right: rem(15px);

							/* 							&:after {
	content:'';
	height: 25px;
	background: $grey;
	display: block;
	width: 1px;
	position: absolute;
	left: 100%;
	top: 50%;
	transform: translateY(-50%);
} */
						}


						&:before {
							@extend .icon-phone;
						}
					}

					.email {
						&:before {
							@extend .icon-mail;
							font-size: .9em;
						}
					}
				}
			}
		}

		.flex-end {
			@include breakpoint(medium) {
				display: flex;
				justify-content: flex-end;
			}

			dl {
				@extend .floatList;

				@include breakpoint(giant) {
					display: flex;
				}


				dt,
				dd {
					width: auto;
				}

				dd {
					&:nth-of-type(1) {
						@include breakpoint(giant) {
							position: relative;
							padding-right: rem(15px);
							margin-right: rem(15px);

							&:after {
								content: '';
								height: rem(25px);
								background: $grey;
								display: block;
								width: 1px;
								position: absolute;
								left: 100%;
								top: 50%;
								transform: translateY(-50%);
							}
						}
					}
				}
			}
		}
	}

	.centered {
		padding-top: 1.5rem;
		padding-bottom: calc(1.5rem + 74px);

		@include breakpoint(small) {
			padding-bottom: 1.5rem;
		}

		@include breakpoint(medium) {
			display: flex;
			align-items: center;
			justify-content: center;
		}



		.navi-add {
			display: flex;
			flex-flow: column;

			@include breakpoint(small) {
				flex-flow: row;
			}


			li {
				position: relative;

				&:not(:last-child) {
					&:after {
						content: '';
						height: 1px;
						width: rem(30px);
						background: rgba($light, .4);
						display: block;
						position: absolute;
						bottom: 0;

						@include breakpoint(small) {
							display: none;
						}

					}
				}

				@include breakpoint(small) {
					position: relative;
					padding-right: rem(15px);
					margin-right: rem(15px);
					margin-bottom: 0;

					&:before {
						content: '';
						height: rem(25px);
						background: $grey;
						display: block;
						width: 1px;
						position: absolute;
						left: 100%;
						top: 50%;
						transform: translateY(-50%);
					}

					&:nth-of-type(2) {
						&:before {
							@include breakpoint(small) {
								display: none;
							}

							@include breakpoint(medium) {
								display: block;
							}

						}
					}
				}


				a {
					color: $light;
					font-weight: 300;
					text-transform: uppercase;
					padding-top: .6rem;
					padding-bottom: .6rem;
					display: block;
				}

				&:hover,
				&.active {
					a {
						color: $orange;
					}
				}

				&.active {
					font-weight: 800;
				}
			}
		}

		.socials {
			display: flex;
			flex-flow: row;
			margin-top: 1rem;
			align-items: center;

			@include breakpoint(medium) {
				margin-top: 0;
				margin-lefT: .4rem;
			}


			li {
				display: flex;
				align-items: center;

				&:nth-of-type(1) {
					a {
						margin-left: 0;
					}
				}

				a {
					margin: .4rem;
					display: flex;
					align-items: center;

					img {
						background: none;
						width: rem(20px);
						height: auto;
					}
				}

				&:hover {
					a {
						opacity: .7;
					}
				}
			}
		}
	}
}

.contact-box {
	ul {
		li {
			&:nth-of-type(2) {
				margin-bottom: 1.2rem;
			}
		}
	}

	form {}
}

.two-column {
	p{
		display: inline-block;
	}
	@include breakpoint(medium) {
		columns: 2;
	}

}

#player86090674 {
	@include breakpoint(full, max) {
		width: 100%;
		height: auto;
		margin-bottom: rem($baseLineHeight);
	}
}
.WidgetPopup{
	@include breakpoint(large,max){
		margin-top: rem(70px);
	}
}