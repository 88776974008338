/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 18px;

$baseLineHeight: 30px;

$h1Size: 32px;

$h2Size: 28px;

$h3Size: 24px;

$h4Size: 22px;

$h5Size: 18px;

$h6Size: 16px;

$mainFont: "Ubuntu", sans-serif;

$displayFont: "Ubuntu", sans-serif;

$iconFont: icomoon;

// Project colors

$primary: #da7c32;
$secondary: #3a6c3a;
$light: #fff;
$lighter: #e7e6de;
$medium: #F2F2F0;
$dark: #262D3B;
$text: #606060;
$border: #bbb;
$alert: #D85D00;
$orange: #da7c32;
$green: #3a6c3a;
$dark-green: #233f23;
$darker: #091d1c;
$grey: #a8a8a8;
$grey-darker: #848e8e;

$baseGap: 18px;

$rowMaxWidth: 1620px;

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	times: "\f00d", 
	minus: "\f068", 
	plus: "\f067", 
	angle-right: "\f0da", 
	angle-left: "\f0d9", 
	angle-up: "\f0d8", 
	angle-down: "\f0d7", 
	exclamation: "\f12a", 
	circle-right: "\e900", 
	circle-left: "\e907", 
	parking: "\e902", 
	phone: "\e903", 
	seed: "\e904", 
	cart: "\e905", 
	car: "\e906", 
	search: "\f002", 
	check: "\f00c", 
	mail: "\f0e0", 
	clock: "\e901", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
	),
	tiny: (
		width: em(480px, $bpContext), 
		cols: (12, 3, 4, 5, 6, 7, 8, 9, 10, 11),
		prefix: (1, 2, 3, 4, 5, 6),
		suffix: (1, 2, 3, 4, 5, 6),
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (12, 3, 4, 5, 6, 7, 8, 9, 10, 11),
		prefix: (1, 2, 3, 4, 5, 6),
		suffix: (1, 2, 3, 4, 5, 6),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (12, 3, 4, 5, 6, 7, 8, 9, 10, 11),
		prefix: (1, 2, 3, 4, 5, 6),
		suffix: (1, 2, 3, 4, 5, 6),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (12, 3, 4, 5, 6, 7, 8, 9, 10, 11),
		prefix: (1, 2, 3, 4, 5, 6),
		suffix: (1, 2, 3, 4, 5, 6),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (12, 3, 4, 5, 6, 7, 8, 9, 10, 11),
		prefix: (1, 2, 3, 4, 5, 6),
		suffix: (1, 2, 3, 4, 5, 6),
	),
	huge: (
		width: em(1364px, $bpContext), 
		cols: (12, 3, 4, 5, 6, 7, 8, 9, 10, 11),
		prefix: (1, 2, 3, 4, 5, 6),
		suffix: (1, 2, 3, 4, 5, 6),
	),
	full: (
		width: em(1520px, $bpContext), 
		cols: (12, 3, 4, 5, 6, 7, 8, 9, 10, 11),
		prefix: (1, 2, 3, 4, 5, 6),
		suffix: (1, 2, 3, 4, 5, 6),
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (auto),
	),
	cut-2000: (
		width: em(2000px, $bpContext), 
	),
	cut-2200: (
		width: em(2000px, $bpContext), 
	),
);

