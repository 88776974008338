// Form styles
form.default {
	overflow: hidden;
	width: 100%;

	&.disabled {
		pointer-events: none;
		opacity:0.5
	}

	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: $dark;
	}

	::-moz-placeholder {
		/* Firefox 19+ */
		color: $dark;
	}

	:-ms-input-placeholder {
		/* IE 10+ */
		color: $dark !important;
	}

	:-moz-placeholder {
		/* Firefox 18- */
		color: $dark;
	}

	fieldset {
		border: none;
	}
	p{
		margin: rem($baseGap) 0;
	}
	a{
		white-space: nowrap;
	}

	&.light {
		[type="text"],
		[type="tel"],
		[type="email"],
		[type="file"],
		input,
		textarea,
		select {
			border: 1px solid rgba($light, .6);
			background-color: transparent;
			color: $light;
		}

		::-webkit-input-placeholder {
			/* Chrome/Opera/Safari */
			color: $light;
		}

		::-moz-placeholder {
			/* Firefox 19+ */
			color: $light;
		}

		:-ms-input-placeholder {
			/* IE 10+ */
			color: $light !important;
		}

		:-moz-placeholder {
			/* Firefox 18- */
			color: $light;
		}
	}

	label,
	input,
	textarea,
	select,
	button,
	[type="submit"] {
		// min-height: 3.5rem;
	}

	legend {
		@extend .h2;
	}

	label {
		color: inherit;
		display: block;
		padding: 1rem 0 0.6rem;
		position: relative;

		small {
			color: $alert;
			display: inline-block;
			line-height: 0.5rem;
		}

		&:not([for="PrivacyPolicy"]) {
			display: none;
		}

		&[for="PrivacyPolicy"] {
			@extend .marginBottom;
			float: left;
			width: calc(100% - #{rem(30px)});
			font-size: 15px;
		}
	}

	small {
		line-height: 1rem;
		display: inline-block;
	}

	[type="text"],
	[type="tel"],
	[type="email"],
	[type="file"],
	input,
	textarea,
	select {
		@extend %animatedTransform;
		background: $light;
		border: 1px solid rgba($dark, .6);
		background-color: transparent;
		color: $dark;
		display: block;
		font-family: $mainFont;
		font-size: rem($baseFontSize);
		line-height: rem($baseLineHeight);
		margin: 0 0 0.5rem 0;
		padding: 0.8rem;
		position: relative;
		resize: none;
		width: 100%;
		border-radius: 0;

		&:focus {
			border: 1px solid $orange;
			outline: none;
		}
	}

	textarea {
		/* fake line-height */
		// min-height: 11.1rem;
		max-height: 11.1rem;
		// height: 100%;
		padding-top: 0.9rem;

		$inputgroup-count: 3;
	}

	[type="checkbox"] {
		display: inline-block;
		// float: right;
		margin: 0 rem(15px) 0.5rem 0;
		padding: 0;
		width: rem(15px);
		height: rem(15px);

		body.iexplore & {
			border: none;

			&:focus {
				background: none;
			}
		}
	}

	[type="radio"] {
		clear: none;
		float: left;
		width: rem(15px);
		margin: 0.4rem 0.8rem 0 0;
		min-height: auto;
	}

	button,
	[type="submit"] {
		@extend .btn;
		width: auto;
		margin-bottom: rem($baseLineHeight);
	}
}

/*=VALIDATION */

.specialfield {
	display: none !important;
	visibility: hidden !important;
}

#newsletter-info {
	background: $alert;
	color: $light;
	font-weight: bold;
	margin-bottom: $baseLineHeight;
	padding: $baseGap;

	*:last-of-type {
		margin-bottom: 0;
	}
}

img[name="vimg"] + input[name="imgverify"] {
	float: right;
}
