* {
  margin: 0;
  padding: 0;

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

::selection {
  background-color: rgba($green, .7);
  color: $light;
}
::-moz-selection {
  background-color: rgba($green, .7);
  color: $light;
}

#pageWrap {
  transition: all 300ms ease-in-out;
}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in _config.scss
html {
  background: $light;
  box-sizing: border-box;
  color: $text;
  font-family: $mainFont;
  font-size: 100% * ($baseFontSize / 16px);
  hyphens: manual;
  line-height: rem($baseLineHeight);
  font-weight: 300;

  @include breakpoint(large, max) {
    a, p, ul, li, dl, dt, dd, ol, table, tbody {
      font-size: 16px;
      line-height: 26px;    
    }
  }
  

  /* disable text resize in landscape. e.g. on iphone */
  text-size-adjust: none;

  &.hiddenScroll {
    #pageWrap {
      filter: blur(4px);
    }
  }
}

body {
  line-height: rem($baseLineHeight);
  opacity: 1 !important;
}

iframe,
[data-iframe] {
  border: none;
  width: 100%;
}

address {
  font-style:normal;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @extend .marginBottom;
  display: block;
  text-rendering: optimizeLegibility;
  hyphens: auto;

  /* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/
}

h1,
.h1 {
  font-family: $displayFont;
  line-height: 1.3em;
  color: $green;
  font-size: 23px;

  @include breakpoint(small) {
    font-size: 27px;
  }

  @include breakpoint(large) {
    font-size: 30px;
  }
  

  @include breakpoint(cut) {
      font-size: 33px;
    }

  span {
    font-weight: 400;
    text-transform: uppercase;
    color: $text;
    display: block;
    font-size: 16px;

    @include breakpoint(small) {
      font-size: 20px;
    }

    @include breakpoint(cut) {
      font-size: 25px;
    }
    
  }

  &.light {
    color: $light;

    span {
      color: $light;
    }
  }
}

h2,
.h2 {
  font-family: $displayFont;
  line-height: 1.3em;
  color: $green;
  font-size: 26px;
  font-weight: 800;

  @include breakpoint(cut) {
      font-size: 27px;
    }

  span {
    font-weight: 400;
    text-transform: uppercase;
    color: $text;
    display: block;
    font-size: 16px;

    @include breakpoint(cut) {
      font-size: 22px;
    }
    
  }

  &.light {
    color: $light;

    span {
      color: $light;
    }
  }
}

h3,
.h3 {
  font-family: $displayFont;
  line-height: 1.3em;
  color: $grey;
  font-size: 22px;
  font-weight: 800;

  @include breakpoint(cut) {
    font-size: 24px;
  }
  
}

h4,
.h4 {
  font-family: $displayFont;
  line-height: 1.3em;
  color: $grey;
  font-size: 20px;
  font-weight: 800;

  @include breakpoint(cut) {
    font-size: 21px;
  }
}

h5,
.h5 {
  font-weight: 800;
  font-size: 18px;
  color: $grey;

  @include breakpoint(large) {
    font-size: 25px;
  }
  
}

h6,
.h6 {
 font-weight: 800;
  font-size: 18px;
  color: $grey;

  @include breakpoint(large) {
    font-size: 21px;
  }
}

/**
 * Links
 */
a {
  color: $primary;
  word-wrap: break-word;
  transition: all 300ms ease-in-out;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    color: $green;
  }
  &:focus {
    color: darken($green, 50%);
  }
  &:active {
    color: darken($green, 30%);
  }

  img {
    border: none;
  }

  &[href^="tel"] {
    color: inherit;
    text-decoration: underline;
  }

  &[data-rel="lightbox:"] {
    display: block;
    position: relative;

    &:hover {
      &:after {
        background: rgba($green, .8) 
      }
    }

    &:after {
      @extend .icon;
      @extend .icon-after-search;
      transition: all 300ms ease-in-out;
      width: 3rem;
      height: 3rem;
      position: absolute;
      left: 0;
      top: 0;
      color: $light;
      background: rgba($green, .4);
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-right-radius: 5px;
    }
  }
}

a:not(.btn):focus,
[tabindex]:focus {
  outline: $outline;
  outline-offset: rem(5px);
}

hr,
.hr {
  display: flex;
  background: darken($medium, 10%);
  border: none;
  clear: both;
  height: rem(1px);
  margin: rem(54px - 30px) 0 rem(54px);
  flex-basis: 100%;
  position: relative;

  &:before {
    @extend .icon;
    @extend .icon-seed;
    color: lighten($green, 20%);
    background-color: $light;
    height: 30px;
    width: 80px;
    font-size: 22px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -55%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include breakpoint(medium) {
    margin: rem(81px - 30px) 0 4.5rem;
  }

  @include breakpoint(huge) {
    margin: rem(90px - 30px) 0 5rem;
  }
}

ul,
ol {
  list-style: none;

  &.styledList {
    li {
      padding-left: rem(15px);
      margin-bottom: rem(7px);

      &:before {
        @extend .icon;
        @extend .icon-angle-right;
        text-indent: rem(-15px);
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}

dl {
  &.styledList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      background: rgba($medium, 0.8);
      padding: rem(15px);
    }

    dt {
      padding-bottom: 0;
      font-weight: bold;
    }

    dd {
      padding-top: 0;

      + dt {
        margin-top: rem(10px);
      }

      + dd {
        margin-top: rem(-15px);
      }
    }
  }

  &.floatList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      vertical-align: top;
      float: left;
    }

    dt {
      padding-right: 0.5rem;
      width: 40%;
      clear: left;
    }

    dd {
      width: 60%;
    }
  }
}

ol {
  &.styledList
  {
    counter-reset: item;
  
    li {
      counter-increment: item;
  
      &:before {
        content: counters(item, ".") ". ";
      }
    }
  
    ol {
      margin-bottom: 0;
  
      li {
        &:before {
          content: counters(item, ".") " ";
          margin-right: rem(5px);
        }
      }
    }
  }
}

/*
 * responsive images
 */

img {
  vertical-align: bottom;
  float: none;
  height: auto;
  max-width: 100%;
  width: 100%;

  &[src^='http://cms.'], &[src^='https://cms.'] {
    max-width: none;
    width: auto;
  }

  [data-rel] &,
  .lightbox-image & {
    margin-bottom:rem($baseLineHeight);
  }

  &[data-src] {
    @extend %lazyloading;
  }
}

/**
 * Tables
 */
main {
  table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-size: rem($baseFontSize);

    @include breakpoint(small, max) {
      overflow-x: auto;
      display: block;
    }

    caption {
      text-align: left;
      padding: rem($baseGap) rem($baseGap) rem(25px);
      display: table-caption;
      font-weight: bold;
      font-size: 1.2rem;
    }

    thead {
      border-collapse: collapse;
      display: table-header-group;
    }

    tbody {
      width: 100%;
      overflow-x: scroll;
      display: table-row-group;

      tr {
        padding: rem($baseGap) 0;

        &:not(:last-child) {
          border-bottom: rem(1px) solid $medium;
        }
      }
    }

    tr {
      display: table-row;
    }

    th,
    td {
      padding: rem($baseGap);
      display: table-cell;
      vertical-align: top;
    }

    @include breakpoint(small, max) {
      td {
        min-width: 50vw;
      }
    }
  }
}
