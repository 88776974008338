// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: rgba($darker, .97); // Navigation Background
$navColor: darken($lighter, 20%); // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
	text-transform: uppercase;
	font-weight: 300;

	@include breakpoint($break) {
		font-size: 15px;
		padding: 0 .5rem;
	}

	@include breakpoint(giant) {
		font-size: 18px;
		padding: 0 .7rem;
	}

	@include breakpoint(huge) {
		padding: 0 rem(19px);
	}
	
	
	
}
%buttonHover {
	// Link Hover
	color: $orange;
	background-color: transparent;
}
%buttonActive {
	// Link Active
	color: $orange;
	font-weight: 800;
	background-color: transparent;
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;
	transition: all 300ms ease-in-out; 

	.branding {
		display: none;
	}

	.shop {
	 display: none;

	    @include breakpoint(large) {
	      display: flex;
	    }
	}

	nav {
		padding-top: 70px;
		height: auto!important;

		@include ul {
			&.navi {
				li {
					@include navButtons {
						@include navButton;
					}

					&:not(:last-child) {
						border-bottom: 1px solid rgba($lighter, .2);
					}

					@include hasSub {
						@include sub {
							background: $navBg;
							padding-top: 70px;
							display: flex;
							flex-flow: column;
							align-items: flex-start;

							&:before {
								padding: 1em rem($baseGap);
								color: $orange;
								font-weight: 800;
								text-transform: uppercase;
								background-color: darken($darker, 3%);
								width: 100%;
							}

							li {
								width: 100%;

								a, span {
									text-transform: none;
								}
							}
						}
					}

					@include navBack {
						order: 1;
						border-bottom: 0;

						@extend .btn;

						color: $light;
						font-weight: 800;
						width: auto!important;
						padding: .6rem 1.3rem;
						margin-top: 1rem;
						margin-left: rem($baseGap);

						&:after {
							display: none;
						}

					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		background: transparent;

		body.cmsBackend & {
			z-index: 2;
		}

		body:not(.index) & {
			background: $darker;
		}


		&.color {
			background: $darker;

			>div {
				padding-top: 25px;
				padding-bottom: 25px;

				.branding {
					@include breakpoint(giant){
						padding-right: 2rem;
					}
					
					img {
						width: 130px;
					}
				}
			}
		}

		> div {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 0 auto;
			max-width: $rowMaxWidth;
			padding: $baseGap; 
			transition: all 300ms ease-in-out;

			
			
			@include breakpoint(giant) {
				// padding-top: 55px;
				// padding-bottom: 55px;
				padding: 55px 30px;
			}
			@include breakpoint(full) {
				padding-right: 140px;
				padding-left: rem($baseGap);
			}
			
			// @include breakpoint(cut) {
			// 	padding-right: 140px;
			// }

			// @include breakpoint(cut-2000) {
			// 	padding-right: 100px;
			// }
			
			

			.branding {
				display: block;
				transition: all 300ms ease-in-out;

				// @include breakpoint(huge) {
				// 	padding-right: 90px;
				// }


				@include breakpoint(cut) {
					padding-right: 70px;
				}
				

				// @include breakpoint(cut-2000) {
				// 	padding-right: 80px;
				// }
				

				img {
					width: 130px;
					height: auto;
					transition: all 300ms ease-in-out;

					@include breakpoint(huge) {
						width: 230px;				
					}
					
				}
			}
		}

		nav {
			padding: 0;

			@include breakpoint(huge) {
				padding-right: 1rem;
			}
			
	
			ul {
				&.navi {
					li {
						&:not(:last-child) {
							border-bottom: 0;
						}

						&.hasSub {

							.sub {
								padding-top: .7rem;
								border-radius: 4px;

								li {
									padding: .7rem;

									&:hover, &.active {
										a {
											&:before {
												background-color: $orange;
											}
										}
									}

									a, span {
										padding: 0.2rem 0.8rem;
										position: relative;
										line-height: 1;

										&:before {
											content:'';
											height: 100%;
											width: 1px;
											display: block;
											background-color: $light;
											position: absolute;
											left: 0;
											top: 50%;
											transform: translateY(-50%);
										}
									}
								}
							}
						}
						&.navBack {
							position: absolute;
							margin: 0;
							height: 30px;
							width: 30px!important;
							padding: 0!important;
							left: 0;
							top: 103%;
							order: 0;

							&:hover {
								background: $green;
								color: $light;
							} 
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: transparent;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 rem($baseGap);
	z-index: 10002;
	transition: all 300ms ease-in-out;

	body:not(.index) & {
		background: $darker;
	}

	&.color {
		background: $darker;
	}

	.branding {
		display: flex;
		align-items: center;
		
		img {
			height: 20px;
			width: auto;
		}
	}

	@include breakpoint($break) {
		display: none;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAdd {
	display: flex;
	justify-content: center;

	li {
		margin-right: 1rem;
	}
}